<template>
    <span class="font-nunito font-black uppercase">{{ game.name }}</span>
    <carousel class="h-64" :images="game.details.images" />

    <template v-if="game.sale != null">
        <price-sticker :item="game.sale" />
        <span class="font-nunito text-xs italic">
            {{ game.sale.isFreightInclusive ? 'Free Shipping' : 'Shipping Extra' }} (7-14 days)
        </span>
        <span class="font-nunito text-xs italic">
            Sold by {{ game.sale.seller?.name ?? 'Cryptic Cardboard LLP' }}
        </span>
    </template>
    
    <span style="font-size:0.75rem; line-height: 1rem;" class="italic">Made in India</span>
    <template v-if="game.state !== 'upcoming'">
        <span class="self-center bg-ccb-amber-400 px-3 py-2 mx-4 mt-4
            font-nunito font-black text-md md:cursor-pointer" @click="checkout()">
            {{ game.state === 'preorder' ? "PRE ORDER" : "BUY NOW" }}
        </span>
    </template>

    <div v-if="game.details.notes !== null" class="mt-4 font-nunito font-light italic text-sm">
        <div v-for="note in game.details.notes" :key="note">{{ note }}</div>
    </div>

    <span class="font-open-sans text-xs mt-4 italic font-thin">Designed by {{ game.details.designers.join(", ") }}</span>
    <span class="font-open-sans text-xs">©
        <span class="italic font-thin">{{ game.details.copyright_year + " " + game.details.copyright_holder }}</span>
    </span>
    <div v-if="game.details.play_time" class="mt-1 flex flex-row gap-x-1 font-nunito">
        <img src="https://detangled.imgix.net/images/clock.svg?auto=format" width="16" height="16">
        {{ game.details.play_time }}
    </div>
    <div v-if="game.details.num_players" class="flex flex-row gap-x-1 font-nunito">
        <img src="https://detangled.imgix.net/images/players.svg?auto=format" width="16" height="16">
        {{ game.details.num_players }}
    </div>
</template>

<script>

import Carousel from "@/components/Carousel.vue";
import PriceSticker from "@/components/PriceSticker.vue";
import { models } from '@ccb/utils';

export default {
    props: { "game": { type: models.Game, required:true } },
    components: { Carousel, PriceSticker },
    methods: {
        checkout() {
            this.$router.push({ path: `/${this.game.canonicalName}/checkout` })
        }
    },
}
</script>
