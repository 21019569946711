<template>
    <game-detail :game="game" />
</template>

<script>
import GameDetail from '@/components/GameDetail.vue';

export default {
    props: [ "game" ],
    components: { GameDetail },
}
</script>