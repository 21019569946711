<template>
    <div class="flex flex-row items-center overflow-x-scroll gap-x-8 py-2">
      <img v-for="image in images" :key="image"
            :src="imagePath(image)"
            :alt="altText(image)"
            class="max-h-full w-auto" />
    </div>
</template>
<script>
export default {
    props: [ "images" ],
    methods: {
        imagePath: (image) => {
            return `https://detangled.imgix.net/${image}?auto=format`;
        },
        altText: (image) => image.split('/').pop().split('-').slice(0, -1).join(' ')
    }
}
</script>
