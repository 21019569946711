<template>
    <div class="flex flex-col w-full justify-start">
        <game-banner :game="game" :isActive="false" />
        <div style="max-width: 960px;" class="container">
            <div class="flex flex-col">
                <game-details-secondary :game="game" />
            </div>
            <div class="flex flex-col">
                <game-details-primary :game="game" />
            </div>
        </div>
    </div>
</template>

<script>

import GameBanner from '@/components/GameBanner.vue';
import GameDetailsPrimary from "@/components/GameDetailsPrimary";
import GameDetailsSecondary from "@/components/GameDetailsSecondary";
import { models } from '@ccb/utils';

export default {
    props: { "game": { type: models.Game, required: true } },
    components: { GameBanner, GameDetailsPrimary, GameDetailsSecondary }
}
</script>
<style scoped lang="postcss">
/* The flex box for the game details has two columns. The right column is the
 * primary and the left column is the secondary. This can wrap if the screen
 * width is low in which case they need to stack on top of each other with the
 * right column coming first. */
.container {
    @apply flex flex-row flex-wrap-reverse p-4 gap-x-8
}

/* We use a flex basis of percentage to ensure that the columns are equal width
 * when they start out. However we don't use 50% since there is flex margin. This
 * way when the screen size is small enough the margin will become greater than
 * 100 - 2 * percentage and cause the flex items to stack. */
.container>* {
    flex: 1 0 47%;
}
</style>