<template>
    <div class="flex flex-col w-full">
        <div>
            <div class="font-nunito mt-4 text-base" v-for="(section, index) in game.details.sections" :key="index">
                <span class="text-ccb-amber-400 font-bold">{{ section.name }}</span>
                <div v-for="line in section.details" :key="line" v-html="line"></div>
            </div>
        </div>

        <div class="py-8">
            <p class="mb-4 font-nunito text-base"
                    v-for="description_line in game.details.description"
                    :key="description_line"
                    v-html="description_line">
            </p>
        </div>

        <div class="flex flex-col gap-y-1" v-if="game.details.rules_video != null">
            <span class="font-nunito font-black text-lg">HOW TO PLAY</span>
            <iframe
                height="240"
                :src="game.details.rules_video"
                title="YouTube video player"
                frameborder="0"
                allowfullscreen></iframe>
            <span class="font-light font-open-sans text-xs">
                Please note: Images and videos are representative. Actual contents may vary.
            </span>
        </div>
    </div>
</template>

<script>
import { models } from '@ccb/utils'

export default {
    props: { "game": { type: models.Game, required:true } },
}
</script>
